<template>
  <div>
    <div class="mb-8">
      <page-header :heading="pageHeading" :sub-heading="pageSubHeading"></page-header>
    </div>
    <div>
      <h2 class="mb-4"
          v-text="heading"
      ></h2>
      <data-table
        table-name="management.table"
        :data-table="dataTable"
        :actions="actions"
        :search-action="searchAction"
      >
        <template #item.uuid="{ item }">
          <copy :text="item.uuid" :display="shortenUuid(item.uuid)"></copy>
        </template>

        <template #item.name="{ item }">
          <copy :text="item.name"></copy>
        </template>

        <template #item.createdOn="{ item }">
          <formatted-date :date="item.createdOn"></formatted-date>
        </template>

        <template #item.updatedOn="{ item }">
          <formatted-date :date="item.updatedOn"></formatted-date>
        </template>
      </data-table>
    </div>
    <component-guard
      :roles="['admin', 'manager']"
      class="mt-8 d-flex justify-end"
    >
      <v-btn
        :to="{ name: 'createTemplate' }"
        color="green"
        class="white--text"
        v-text="createTemplateButtonText"
      ></v-btn>
    </component-guard>
    <v-snackbar
      v-model="copyJsonSuccessSnackbar"
      color="success"
      timeout="3000"
      min-width="auto"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-2">{$icons.mdiCheck}</v-icon>
        <span>{{ copyJsonSuccessText }}</span>
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="saveTemplateSuccessSnackbar"
      color="success"
      timeout="3000"
      min-width="auto"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-2">{{ $icons.mdiCheck }}</v-icon>
        <span>{{ saveTemplateSuccessText }}</span>
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="deleteTemplateSuccessSnackbar"
      color="success"
      timeout="3000"
      min-width="auto"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-2">{{ $icons.mdiCheck }}</v-icon>
        <span>{{ deleteTemplateSuccessText }}</span>
      </div>
    </v-snackbar>

    <confirmation-dialog
      :loading="loading"
      v-model="deleteDialog"
      :title="deleteDialogTitle"
      :text="deleteDialogText"
      :cancel="deleteDialogCancelText"
      :confirm="deleteDialogConfirmText"
      confirm-color="red"
      @cancel="resetDeleteDialog"
      @confirm="confirmDelete"
    ></confirmation-dialog>

    <rendered-preview
      v-model="preview"
      :title="previewTitle"
    >
      <template #actions>
        <v-menu
          bottom
          left
          :min-width="200"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="d-sm-none"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ $icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="copyJsonHandler(currentItem)"
              v-html="copyJsonButtonTooltip"
            ></v-list-item>
            <v-list-item
              @click="duplicateHandler(currentItem)"
              :disabled="isDuplicateButtonDisabled(currentItem)"
              v-html="duplicateButtonTooltip"
            ></v-list-item>
            <v-list-item
              @click="updateHandler(currentItem)"
              :disabled="isEditButtonDisabled(currentItem)"
              v-html="editButtonTooltip"
            ></v-list-item>
            <v-list-item
              @click="deleteHandler(currentItem)"
              :disabled="isDeleteButtonDisabled(currentItem)"
              v-html="deleteButtonTooltip"
            ></v-list-item>
          </v-list>
        </v-menu>
        <div class="d-none d-sm-block">
          <v-tooltip
            left
            :open-delay="250"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                dark
                @click="copyJsonHandler(currentItem)"
              >
                <v-icon>{{ $icons.mdiContentCopy }}</v-icon>
              </v-btn>
            </template>
            <span v-html="copyJsonButtonTooltip"></span>
          </v-tooltip>
          <v-tooltip
            left
            :open-delay="250"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                dark
                :disabled="isDuplicateButtonDisabled(currentItem)"
                @click="duplicateHandler(currentItem)"
              >
                <v-icon>{{ $icons.mdiContentDuplicate }}</v-icon>
              </v-btn>
            </template>
            <span v-html="duplicateButtonTooltip"></span>
          </v-tooltip>
          <v-tooltip
            left
            :open-delay="250"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                dark
                :disabled="isEditButtonDisabled(currentItem)"
                @click="updateHandler(currentItem)"
              >
                <v-icon>{{ $icons.mdiFileDocumentEditOutline }}</v-icon>
              </v-btn>
            </template>
            <span v-html="editButtonTooltip"></span>
          </v-tooltip>
          <v-tooltip
            left
            :open-delay="250"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                dark
                :disabled="isDeleteButtonDisabled(currentItem)"
                @click="deleteHandler(currentItem)"
              >
                <v-icon>{{ $icons.mdiDelete }}</v-icon>
              </v-btn>
            </template>
            <span v-html="deleteButtonTooltip"></span>
          </v-tooltip>
        </div>
      </template>
    </rendered-preview>
  </div>
</template>

<script>
import Copy from '@/components/Copy'
import PageHeader from '@/components/PageHeader'
import Page from '@/mixins/page.mixin'
import i18n from '@/mixins/i18n.mixin'
import { mapActions, mapGetters } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog'

import { transformObjectKeys } from '@/helpers/api.helper'
import { shortenUuid } from '@/helpers/utility.helper'

import snakeCase from 'lodash/snakeCase'

import DataTable from '@/components/DataTable'
import FormattedDate from '@/components/FormattedDate'

import RenderedPreview from '@/components/contract-summaries/preview/RenderedPreview'

export default {
  name: 'Templates',
  mixins: [
    i18n,
    Page
  ],
  components: {
    Copy,
    PageHeader,
    DataTable,
    FormattedDate,
    ConfirmationDialog,
    RenderedPreview
  },
  data () {
    return {
      primaryField: 'uuid',
      searchTerm: '',
      copyJsonSuccessSnackbar: false,
      saveTemplateSuccessSnackbar: false,
      deleteTemplateSuccessSnackbar: false,
      preview: false,
      currentItem: null,
      deleteDialog: false,
      deleteTemplateItem: null
    }
  },
  mounted () {
    this.fetchDefaultValues()
    this.listContractSummaries()
    if (this.$route.query.saved) {
      this.saveTemplateSuccessSnackbar = true
      history.replaceState({}, this.pageTitle, this.$route.path)
    }
  },
  methods: {
    ...mapActions('contractSummaries', ['deleteContractSummary', 'setFormComplete', 'setControlDataComplete', 'reset', 'setContractSummaryTemplate', 'listContractSummaries', 'fetchDefaultValues']),
    searchHandler (term) {
      this.searchTerm = term
    },
    previewHandler (item) {
      this.currentItem = item
      this.setContractSummaryTemplate(item)
      this.preview = true
    },
    copyJsonHandler (item) {
      const transformed = transformObjectKeys(item.template, (str) => snakeCase(str).replaceAll(/_(\d)/g, '$1'))
      navigator
        .clipboard
        .writeText(JSON.stringify(transformed, null, 2))
        .then(() => {
          this.copyJsonSuccessSnackbar = true
        })
    },
    duplicateHandler (item) {
      this.preview = false
      this.$router.push({ name: 'duplicateTemplate', params: { templateUuid: item.uuid } })
    },
    updateHandler (item) {
      this.preview = false
      this.$router.push({ name: 'updateTemplate', params: { templateUuid: item.uuid } })
    },
    deleteHandler (item) {
      this.deleteTemplateItem = item
      this.deleteDialog = true
    },
    resetDeleteDialog () {
      this.deleteTemplateItem = null
      this.deleteDialog = false
    },
    confirmDelete () {
      this.deleteContractSummary(this.deleteTemplateItem.uuid)
        .then(() => {
          this.deleteTemplateSuccessSnackbar = true
          this.preview = false
          this.deleteDialog = false
        })
    },
    isDuplicateButtonDisabled () {
      return !this.isAdmin && !this.isManager
    },
    isEditButtonDisabled () {
      return !this.isAdmin && !this.isManager
    },
    isDeleteButtonDisabled () {
      return !this.isAdmin && !this.isManager
    },
    shortenUuid
  },
  computed: {
    ...mapGetters('contractSummaries', ['contractSummaryTemplates', 'loading', 'controlData', 'defaultPriceTableEntries']),
    ...mapGetters('keycloak', ['isAdmin', 'isManager']),
    dataTable () {
      return {
        headers: this.headers,
        items: this.contractSummaryTemplates,
        loading: this.loading,
        showSelect: false,
        itemKey: this.primaryField,
        sortBy: 'createdOn',
        sortDesc: true,
        disablePagination: true,
        hideDefaultFooter: true,
        search: this.searchTerm
      }
    },
    headers () {
      return [
        { value: 'uuid' },
        { value: 'name' },
        { value: 'createdOn' },
        { value: 'updatedOn' },
        { value: 'updatedUser.preferredUsername' },
        { value: 'actions', sortable: false, align: 'end' }
      ]
    },
    actions () {
      return [
        { icon: this.$icons.mdiFullscreen, handler: this.previewHandler, tooltip: this.$tp('components.management.table.actions.preview') },
        { icon: this.$icons.mdiContentCopy, handler: this.copyJsonHandler, tooltip: this.copyJsonButtonTooltip },
        { icon: this.$icons.mdiContentDuplicate, handler: this.duplicateHandler, tooltip: this.duplicateButtonTooltip, disabled: this.isDuplicateButtonDisabled },
        { icon: this.$icons.mdiFileDocumentEditOutline, handler: this.updateHandler, tooltip: this.editButtonTooltip, disabled: this.isEditButtonDisabled },
        { icon: this.$icons.mdiDelete, handler: this.deleteHandler, tooltip: this.deleteButtonTooltip, disabled: this.isDeleteButtonDisabled }
      ]
    },
    searchAction () {
      return {
        label: this.$tp('components.management.table.search.label'),
        hint: this.$tp('components.management.table.search.hint'),
        handler: this.searchHandler
      }
    },
    copyJsonSuccessText () {
      return this.$tp('components.management.snackbars.copyJsonSuccessText')
    },
    saveTemplateSuccessText () {
      return this.$tp('components.management.snackbars.saveTemplateSuccessText')
    },
    deleteTemplateSuccessText () {
      return this.$tp('components.management.snackbars.deleteTemplateSuccessText')
    },
    copyJsonButtonTooltip () {
      return this.$tp('components.management.table.actions.copyJson')
    },
    duplicateButtonTooltip () {
      return this.$tp('components.management.table.actions.duplicate')
    },
    editButtonTooltip () {
      return this.$tp('components.management.table.actions.edit')
    },
    deleteButtonTooltip () {
      return this.$tp('components.management.table.actions.delete')
    },
    deleteDialogTitle () {
      return this.$tp('components.management.dialogs.delete.title')
    },
    deleteDialogText () {
      if (this.deleteTemplateItem !== null) {
        return this.$t('pages.templates.components.management.dialogs.delete.text', { template: this.deleteTemplateItem.name || '' })
      } else {
        return ''
      }
    },
    deleteDialogCancelText () {
      return this.$tp('components.management.dialogs.delete.cancelButtonText')
    },
    deleteDialogConfirmText () {
      return this.$tp('components.management.dialogs.delete.deleteButtonText')
    },
    previewTitle () {
      return this.$t('pages.templates.components.management.dialogs.preview.title', { template: this.currentItem?.name ?? '' })
    },
    heading () {
      return this.$tp('components.management.heading')
    },
    createTemplateButtonText () {
      return this.$tp('components.management.buttons.createTemplateButtonText')
    }
  },
  watch: {
    preview (newValue) {
      if (!newValue) {
        this.currentItem = null
        this.setContractSummaryTemplate(null)
      }
    }
  }
}
</script>
